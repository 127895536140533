import { default as indexkCE9qrkJ1kMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/404/index.vue?macro=true";
import { default as indexWkemWuZlWuMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/admin/index.vue?macro=true";
import { default as indexM1u9gzE64ZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/index.vue?macro=true";
import { default as indexS8PCbKbD9mMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/timeline/index.vue?macro=true";
import { default as _91id_93wnL5p53D5HMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id].vue?macro=true";
import { default as indexr1vrMPM1KGMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/bounced/index.vue?macro=true";
import { default as indexqhCilGifF6Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/index.vue?macro=true";
import { default as indexAI1GExP2rrMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/markedspam/index.vue?macro=true";
import { default as indexiQjp2WCARsMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/unsubscribed/index.vue?macro=true";
import { default as tableAM3up6x1g3Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table.vue?macro=true";
import { default as indexhwrTCPQHpRMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id]/index.vue?macro=true";
import { default as _91id_937qH831lmQKMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id].vue?macro=true";
import { default as indexWzF9HNHzaAMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields/index.vue?macro=true";
import { default as match_45fieldskwlSRYrdfMMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields.vue?macro=true";
import { default as indexG6A2c369fSMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file/index.vue?macro=true";
import { default as select_45fileHJvmhnXO67Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file.vue?macro=true";
import { default as _91id_93ookj9pLxoMMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id].vue?macro=true";
import { default as newVH0gP6GDkUMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new.vue?macro=true";
import { default as indexUA25xPXUo2Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table/index.vue?macro=true";
import { default as tableoXThz8XD7YMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table.vue?macro=true";
import { default as indexdvxWAYhvIaMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/edit/index.vue?macro=true";
import { default as indexQMV8hYPCAmMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/index.vue?macro=true";
import { default as indexyoAjEpJBv6Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/new/index.vue?macro=true";
import { default as indexAEzHGjG2lhMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table/index.vue?macro=true";
import { default as tabledaLD92Sl7FMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table.vue?macro=true";
import { default as index82KtHcZvrwMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id]/index.vue?macro=true";
import { default as _91id_93xzTWsXIIvfMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id].vue?macro=true";
import { default as indexdckTNWfY0lMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields/index.vue?macro=true";
import { default as match_45fieldsFOevKjxc8EMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields.vue?macro=true";
import { default as indexsodgHFJgIgMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file/index.vue?macro=true";
import { default as select_45file26YZWEojC4Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file.vue?macro=true";
import { default as _91id_93jQiCsfzvFsMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id].vue?macro=true";
import { default as newoOnAlWmgTKMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new.vue?macro=true";
import { default as indexm4IKTjzoY2Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table/index.vue?macro=true";
import { default as tableITD1FnXegMMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table.vue?macro=true";
import { default as index0kltFN51KdMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/configure/index.vue?macro=true";
import { default as indexIMDL0crAsNMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/edit/index.vue?macro=true";
import { default as indexHIvlQVN286Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/name/index.vue?macro=true";
import { default as _91id_938pOaAhOyHKMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id].vue?macro=true";
import { default as indexr6aYK9CAqkMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/info/[id]/index.vue?macro=true";
import { default as index14XQ4vYB9RMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/configure/index.vue?macro=true";
import { default as indexwoYoMuBkMkMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/create/index.vue?macro=true";
import { default as indexKLq3hkSsCOMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/name/index.vue?macro=true";
import { default as newEG3IAFiASHMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new.vue?macro=true";
import { default as tableJNPw0RdjeHMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/table.vue?macro=true";
import { default as indexLEsu5MkORyMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/bulk-action/table/index.vue?macro=true";
import { default as editR5yVreLjXrMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/edit.vue?macro=true";
import { default as indexSS8CdvV65QMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/index.vue?macro=true";
import { default as index3MeVYi4Y78Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/performance/index.vue?macro=true";
import { default as overvieweyacFGhZaWMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview.vue?macro=true";
import { default as goalZyl5juUjJhMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/goal.vue?macro=true";
import { default as reportpW8AVhtwFHMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/report.vue?macro=true";
import { default as timelinezX36nSqcQGMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/timeline.vue?macro=true";
import { default as _91id_93cpCvmt2BA4Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id].vue?macro=true";
import { default as indexKEwIei4EpVMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/edit/index.vue?macro=true";
import { default as indexwWbMM0y4s2Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/goal/index.vue?macro=true";
import { default as indexGJ0QkNZvVqMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/template/index.vue?macro=true";
import { default as indexBlTPNIwxOSMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/timeline/index.vue?macro=true";
import { default as newZeLrZoZUWNMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new.vue?macro=true";
import { default as indexWtagrisiPQMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/active/index.vue?macro=true";
import { default as indexFwBln0tj6dMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/draft/index.vue?macro=true";
import { default as indexQh3yMwIiSfMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/index.vue?macro=true";
import { default as indexTwnC29e9ChMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/paused/index.vue?macro=true";
import { default as tableaGX6sfKCZ3Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table.vue?macro=true";
import { default as indexCSyHjCXaDKMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/index.vue?macro=true";
import { default as indexf52Z5FdHMiMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/performance/index.vue?macro=true";
import { default as overviewI5INdtBHJKMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview.vue?macro=true";
import { default as index9rtJNU1f4nMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/index.vue?macro=true";
import { default as indexj0ftJTqyFRMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/index.vue?macro=true";
import { default as indexloJuNdGfOeMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/performance/index.vue?macro=true";
import { default as overviewYdifhnKenaMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview.vue?macro=true";
import { default as indexNM73zW5sz1Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/content/index.vue?macro=true";
import { default as index9cVO6Y2UjrMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/design/index.vue?macro=true";
import { default as index0cNVlz4BVAMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/recipient/index.vue?macro=true";
import { default as index8G1fuFaFNcMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/review/index.vue?macro=true";
import { default as indexjk4X6awR8GMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/schedule/index.vue?macro=true";
import { default as indexJkd0FOvkA6Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/sender/index.vue?macro=true";
import { default as _91id_93e0Gfn4sONtMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id].vue?macro=true";
import { default as index1tbYfv0VM6Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/content/index.vue?macro=true";
import { default as indexmFaPIEfGXgMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/design/index.vue?macro=true";
import { default as indexSlpoKpzrpPMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/recipient/index.vue?macro=true";
import { default as indexcj1WaqCHfsMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/review/index.vue?macro=true";
import { default as indexNn45EF0YGZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/schedule/index.vue?macro=true";
import { default as indexCeryqv3VwFMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/sender/index.vue?macro=true";
import { default as newWVyS7fdwPnMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new.vue?macro=true";
import { default as indexudlXcMMBHUMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/draft/index.vue?macro=true";
import { default as indexdSmZEGCF8MMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/finished/index.vue?macro=true";
import { default as indexSZQP7awdC1Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/index.vue?macro=true";
import { default as index7xbjWESBkEMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/scheduled/index.vue?macro=true";
import { default as indexw3EA7iYW2bMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/tested/index.vue?macro=true";
import { default as index73rs6oFWxgMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/testing/index.vue?macro=true";
import { default as indextZpeTh71VZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerScheduled/index.vue?macro=true";
import { default as indexCsGJuIillhMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerSending/index.vue?macro=true";
import { default as tableqPEWVyjwamMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table.vue?macro=true";
import { default as indexKKUhJcXtmtMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/index.vue?macro=true";
import { default as indexLidWF1W9WuMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/performance/index.vue?macro=true";
import { default as overviewn77H8rWae5Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview.vue?macro=true";
import { default as indexIMvmUhVBhmMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/content/index.vue?macro=true";
import { default as index0435nQqBR6Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/design/index.vue?macro=true";
import { default as indext14IMmUnVpMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/review/index.vue?macro=true";
import { default as indexxTlS5Zu6aeMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/sender/index.vue?macro=true";
import { default as indexk25xnVasWbMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/trigger/index.vue?macro=true";
import { default as _91id_93qZfD1sJoqTMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id].vue?macro=true";
import { default as index7lYeqznczqMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/content/index.vue?macro=true";
import { default as indexjzn2FI5jJTMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/design/index.vue?macro=true";
import { default as indexgbsXwS616XMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/review/index.vue?macro=true";
import { default as indexst0Oo9nu5yMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/sender/index.vue?macro=true";
import { default as indexP9LMcjk9ZBMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/trigger/index.vue?macro=true";
import { default as newun9pqjVxZGMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new.vue?macro=true";
import { default as indexO0suNSZQDwMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/active/index.vue?macro=true";
import { default as indexl1HGbweZv3Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/draft/index.vue?macro=true";
import { default as indexyNvdo2BYvbMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/index.vue?macro=true";
import { default as tablewVvZYN97SlMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table.vue?macro=true";
import { default as indexDAce4A2O1cMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/index.vue?macro=true";
import { default as indexOEodS0MP3bMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/index.vue?macro=true";
import { default as indexUBNnxrSnsYMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/performance/index.vue?macro=true";
import { default as overview5TGDx9ykJkMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview.vue?macro=true";
import { default as indexekhDmJdy4NMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/content/index.vue?macro=true";
import { default as indexK7Fb7T9ZDSMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/sender/index.vue?macro=true";
import { default as _91id_93S2dTszNYiYMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id].vue?macro=true";
import { default as indexz5wPof3ARoMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/content/index.vue?macro=true";
import { default as index9VwMbbHJ0wMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/sender/index.vue?macro=true";
import { default as new3tF3J80pdKMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new.vue?macro=true";
import { default as index6j42cymmxjMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/active/index.vue?macro=true";
import { default as indexqqkzIIZOp6Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/index.vue?macro=true";
import { default as indexe9LqpwQHQZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/paused/index.vue?macro=true";
import { default as tableqf2afO8gybMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table.vue?macro=true";
import { default as indexMQZ56phUhFMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/content/index.vue?macro=true";
import { default as indexZJA4TE78DXMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/design/index.vue?macro=true";
import { default as indexQ9uVkwvqDIMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/recipient/index.vue?macro=true";
import { default as indexln5m0hWno3Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/review/index.vue?macro=true";
import { default as indexzaqkBN77TrMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/schedule/index.vue?macro=true";
import { default as indexGtkiUz1nCqMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/sender/index.vue?macro=true";
import { default as _91id_93m7PDzCvp8NMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id].vue?macro=true";
import { default as indexonecWUZIsdMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/content/index.vue?macro=true";
import { default as index3QBJIku6dIMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/design/index.vue?macro=true";
import { default as indexPB5pD5oIgHMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/recipient/index.vue?macro=true";
import { default as indexcOpIPmDT96Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/review/index.vue?macro=true";
import { default as indextYbetsRGBwMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/schedule/index.vue?macro=true";
import { default as indextpmhK14bAjMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/sender/index.vue?macro=true";
import { default as newgnl7i0uI8xMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new.vue?macro=true";
import { default as indexoyrSKg9eMpMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/draft/index.vue?macro=true";
import { default as indexDTg2nms23zMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/index.vue?macro=true";
import { default as indexJ6ZJUhMJ4xMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/quarantined/index.vue?macro=true";
import { default as indexoG2CdPmsLMMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/scheduled/index.vue?macro=true";
import { default as indexgyRUukb0CTMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sending/index.vue?macro=true";
import { default as indexVlLASWpJDDMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sent/index.vue?macro=true";
import { default as table2axTko2KjmMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table.vue?macro=true";
import { default as indexzvvyCkyS1kMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/report/index.vue?macro=true";
import { default as indexijus1cht3cMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/index.vue?macro=true";
import { default as indexC5AaarrHnOMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/index.vue?macro=true";
import { default as index08cce0sc66Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/performance/index.vue?macro=true";
import { default as overviewHbFwZ6MhaeMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview.vue?macro=true";
import { default as indexzHrWFUjECUMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/basic/index.vue?macro=true";
import { default as index4nQywI1thAMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/content/index.vue?macro=true";
import { default as indexxhcIvlZSj3Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/recipient/index.vue?macro=true";
import { default as indexfNOv5SR8XTMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/schedule/index.vue?macro=true";
import { default as _91id_93HaDbowSxPjMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id].vue?macro=true";
import { default as indexmnPFqNxFSQMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/basic/index.vue?macro=true";
import { default as index50imaiZOvCMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/content/index.vue?macro=true";
import { default as index7DbfXYvEKcMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/recipient/index.vue?macro=true";
import { default as indexBESmua1AhfMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/schedule/index.vue?macro=true";
import { default as neweXezCSCpQDMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new.vue?macro=true";
import { default as indexHIT6XbzalsMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/active/index.vue?macro=true";
import { default as indexd65ljMERqbMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/draft/index.vue?macro=true";
import { default as indexwMSRYKYc31Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/index.vue?macro=true";
import { default as indexWq4MZyP3jMMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/paused/index.vue?macro=true";
import { default as tablebSKj59uXmdMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table.vue?macro=true";
import { default as indexDYVd29JlZjMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/dashboard/index.vue?macro=true";
import { default as indexV9HAoH8nHdMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/download/index.vue?macro=true";
import { default as indexduFPyMACgZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/error/index.vue?macro=true";
import { default as indexv4SM4YLLdmMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/index.vue?macro=true";
import { default as indexmzE74WItPPMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/instructions/index.vue?macro=true";
import { default as indexEgRvk1s6ogMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/performance/index.vue?macro=true";
import { default as indexmD5b46geHXMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/submission/index.vue?macro=true";
import { default as overviewvh8vur7XBeMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview.vue?macro=true";
import { default as indexGj5O9yQXOnMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/basic/index.vue?macro=true";
import { default as indexVnruggH9dlMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/content/index.vue?macro=true";
import { default as indexjpnqKXaqK7Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/design/index.vue?macro=true";
import { default as indexEnWI8CIhbWMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/success/index.vue?macro=true";
import { default as indexdHStBcAfbDMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/targeting/index.vue?macro=true";
import { default as _91id_93RkMRHJuOMPMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id].vue?macro=true";
import { default as indexYrPMjH9pvYMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/index.vue?macro=true";
import { default as indexm49k5fIByoMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/instructions/index.vue?macro=true";
import { default as indexyJpg1zJG3SMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/performance/index.vue?macro=true";
import { default as indexQAcjgm0zQWMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/submission/index.vue?macro=true";
import { default as overviewg7EECaEa3OMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview.vue?macro=true";
import { default as indexwCCmjuUpoMMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/basic/index.vue?macro=true";
import { default as indexp1yaC6wHuaMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/content/index.vue?macro=true";
import { default as indexb3jrs2P6a3Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/design/index.vue?macro=true";
import { default as index8gUbzRzm0PMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success-page/index.vue?macro=true";
import { default as indexkkGwPzHUKBMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success/index.vue?macro=true";
import { default as _91id_93BOM0SrZhpuMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id].vue?macro=true";
import { default as EmbeddedFormfb8L5GpNmQMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/EmbeddedForm.vue?macro=true";
import { default as indexOxrXrCATyZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/index.vue?macro=true";
import { default as LandingPageVpBlBubsUrMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/LandingPage.vue?macro=true";
import { default as PopupDMIDjHOU95Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/Popup.vue?macro=true";
import { default as index4F3CjD79ggMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/behaviour/index.vue?macro=true";
import { default as EmbeddedFormfNwiTXJeTyMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm.vue?macro=true";
import { default as EmbeddedForm2IuIWGgnM33Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm2.vue?macro=true";
import { default as indexQAymyb7zuLMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/index.vue?macro=true";
import { default as LandingPage0nXDFjibGSMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/LandingPage.vue?macro=true";
import { default as PopupYvfm5Tn9IVMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup.vue?macro=true";
import { default as Popup2J7bCPkIZX0Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup2.vue?macro=true";
import { default as EmbeddedFormqZ5dOXdjoGMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/EmbeddedForm.vue?macro=true";
import { default as indexMDFWhzi8PXMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/index.vue?macro=true";
import { default as LandingPage55vY9bb1qfMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/LandingPage.vue?macro=true";
import { default as PopupqU74GYsXJsMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/Popup.vue?macro=true";
import { default as indexEuZ8E3ODfNMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success-page/index.vue?macro=true";
import { default as EmbeddedFormCwq5NJieTWMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/EmbeddedForm.vue?macro=true";
import { default as indexFDqzac563OMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/index.vue?macro=true";
import { default as LandingPageAEsMYNXsxZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/LandingPage.vue?macro=true";
import { default as PopupPdRJLNWpyEMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/Popup.vue?macro=true";
import { default as EmbeddedFormQXl3npE8V0Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/EmbeddedForm.vue?macro=true";
import { default as indexXUAVLKuwFQMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/index.vue?macro=true";
import { default as PopupFgWT5uiWZcMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/Popup.vue?macro=true";
import { default as index5oT6W0eb8yMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/type/index.vue?macro=true";
import { default as newBrKHWsWdxPMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new.vue?macro=true";
import { default as indexTqsJfwNjUlMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/index.vue?macro=true";
import { default as indexJMKNb2UAfiMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/instructions/index.vue?macro=true";
import { default as indexbcZStL8vNZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/performance/index.vue?macro=true";
import { default as indexOKgdQNQWkoMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/submission/index.vue?macro=true";
import { default as overviewCogqlyXmKyMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview.vue?macro=true";
import { default as indexxHPtrYr131Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/basic/index.vue?macro=true";
import { default as indexd50mbgM4jSMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/behaviour/index.vue?macro=true";
import { default as indexsFZ6NM9JxeMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/content/index.vue?macro=true";
import { default as index62HHRks9GPMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/design/index.vue?macro=true";
import { default as indexdrWmgUUTtGMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/success/index.vue?macro=true";
import { default as indexQJpfXNDbGLMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/targeting/index.vue?macro=true";
import { default as _91id_93Q8cBTrv8cqMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id].vue?macro=true";
import { default as indexkhqZv8KQ1UMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/embed/index.vue?macro=true";
import { default as indexR4ylKG7DaFMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/landing-page/index.vue?macro=true";
import { default as indexPNlPchZng8Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/popup/index.vue?macro=true";
import { default as tablellxK9Ofsn4Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table.vue?macro=true";
import { default as index6MhSCqgr6HMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/index.vue?macro=true";
import { default as indexpynyYqhSASMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/click-funnels/index.vue?macro=true";
import { default as indexTdHWilxcodMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/convert-box/index.vue?macro=true";
import { default as indexlJei7uuEHpMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/demio/index.vue?macro=true";
import { default as indexEO3kLvXuO0Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/hubspot/index.vue?macro=true";
import { default as indexMz0emqpBxoMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/instapage/index.vue?macro=true";
import { default as indexjoudENH79AMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/intercom/index.vue?macro=true";
import { default as indexFT6MHgFmPCMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/paypal/index.vue?macro=true";
import { default as indexmNEatce6T3Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/pipedrive/index.vue?macro=true";
import { default as indexWghSBctyA9Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/samcart/index.vue?macro=true";
import { default as index2p5chpplQRMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/stripe/index.vue?macro=true";
import { default as index1oVuqco6j8Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/teachable/index.vue?macro=true";
import { default as indexAonEJWFX2kMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/twilio/index.vue?macro=true";
import { default as indexLKLqRXEjj5Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woo-commerce/index.vue?macro=true";
import { default as indexdRuIdALZ2HMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woorise/index.vue?macro=true";
import { default as indexsFIV2mmtuAMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/zapier/index.vue?macro=true";
import { default as indexG9hivKzlCrMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/login/index.vue?macro=true";
import { default as indexzo1Niq7afyMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/index.vue?macro=true";
import { default as indexu8EBxBat6gMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/member/index.vue?macro=true";
import { default as indexiYVhr1NJ6qMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/profile/index.vue?macro=true";
import { default as indexjzIXl8Ij93Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/register/index.vue?macro=true";
import { default as indexfs1GLJA5CyMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/index.vue?macro=true";
import { default as indexXFfsC9MWPYMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/newpassword/index.vue?macro=true";
import { default as indexlYxm3iqxB8Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/billing/index.vue?macro=true";
import { default as indextvIwGhWvxDMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/api/index.vue?macro=true";
import { default as indexfHANA8mgtIMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/webhook/index.vue?macro=true";
import { default as connectorsubOVOu0lcUMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors.vue?macro=true";
import { default as _91id_931RI4AnIfzlMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/[id].vue?macro=true";
import { default as indexjFUdA0rL0WMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/index.vue?macro=true";
import { default as indexgOvXDX6JtAMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-fields/index.vue?macro=true";
import { default as custom_45propertiesmi5U5Xfl9MMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties.vue?macro=true";
import { default as indexnm35yWaSjaMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/home-page/index.vue?macro=true";
import { default as indexjd4ig0L0aeMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/add-ons/index.vue?macro=true";
import { default as indexrCZxm19hGOMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/integrations/index.vue?macro=true";
import { default as indextSsMNnUPCrMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/partners/index.vue?macro=true";
import { default as marketplace5YvCIT5DqCMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace.vue?macro=true";
import { default as indexNwWQsl4jyFMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/company-information/index.vue?macro=true";
import { default as index1twFt3p1WcMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/domain-whitelisting/index.vue?macro=true";
import { default as indexjfGfPWcBxKMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/editor/index.vue?macro=true";
import { default as indexlqxJAkVZMvMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/sender/index.vue?macro=true";
import { default as indexLpDBPkmRcZMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/template/index.vue?macro=true";
import { default as indexSHYiCa2kQWMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/unsubscribe/index.vue?macro=true";
import { default as setupSiPTsNZNKpMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup.vue?macro=true";
import { default as indexbz711KhV01Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/team/members/index.vue?macro=true";
import { default as settingfCgfPHNSOIMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting.vue?macro=true";
import { default as indexF5H7CFPYBNMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/team/index.vue?macro=true";
import { default as indexcNjujhfVwnMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/edit/index.vue?macro=true";
import { default as indexFoAKDW2jOBMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/index.vue?macro=true";
import { default as indexwQl3Dy6ATNMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/new/index.vue?macro=true";
import { default as indexXr5F6lV8FHMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details/index.vue?macro=true";
import { default as detailsQd0ZRXYL1JMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details.vue?macro=true";
import { default as indexRMien9haGfMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/new/index.vue?macro=true";
import { default as indexrJ2v0phNE4Meta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table/index.vue?macro=true";
import { default as tableCvBg3SvZiWMeta } from "/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/404/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexWkemWuZlWuMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wnL5p53D5HMeta?.name,
    path: "/audience/contact/info/:id()",
    meta: _91id_93wnL5p53D5HMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "audience-contact-info-id",
    path: "",
    meta: indexM1u9gzE64ZMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-info-id-timeline",
    path: "timeline",
    meta: indexS8PCbKbD9mMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/timeline/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tableAM3up6x1g3Meta?.name,
    path: "/audience/contact/table",
    meta: tableAM3up6x1g3Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-contact-table-bounced",
    path: "bounced",
    meta: indexr1vrMPM1KGMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/bounced/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-table",
    path: "",
    meta: indexqhCilGifF6Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-table-markedspam",
    path: "markedspam",
    meta: indexAI1GExP2rrMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/markedspam/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-table-unsubscribed",
    path: "unsubscribed",
    meta: indexiQjp2WCARsMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/unsubscribed/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_937qH831lmQKMeta?.name,
    path: "/audience/list/details/:id()",
    meta: _91id_937qH831lmQKMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-details-id",
    path: "",
    meta: indexhwrTCPQHpRMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "audience-list-new",
    path: "/audience/list/new",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-new-import-id",
    path: "import/:id()",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id].vue").then(m => m.default || m),
    children: [
  {
    name: match_45fieldskwlSRYrdfMMeta?.name,
    path: "match-fields",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-new-import-id-match-fields",
    path: "",
    meta: indexWzF9HNHzaAMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: select_45fileHJvmhnXO67Meta?.name,
    path: "select-file",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-new-import-id-select-file",
    path: "",
    meta: indexG6A2c369fSMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: tableoXThz8XD7YMeta?.name,
    path: "/audience/list/table",
    meta: tableoXThz8XD7YMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-table",
    path: "",
    meta: indexUA25xPXUo2Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "audience-segment-id-edit",
    path: "/audience/segment/:id()/edit",
    meta: indexdvxWAYhvIaMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-segment-id",
    path: "/audience/segment/:id()",
    meta: indexQMV8hYPCAmMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-segment-new",
    path: "/audience/segment/new",
    meta: indexyoAjEpJBv6Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/new/index.vue").then(m => m.default || m)
  },
  {
    name: tabledaLD92Sl7FMeta?.name,
    path: "/audience/segment/table",
    meta: tabledaLD92Sl7FMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-segment-table",
    path: "",
    meta: indexAEzHGjG2lhMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93xzTWsXIIvfMeta?.name,
    path: "/audience/tag/details/:id()",
    meta: _91id_93xzTWsXIIvfMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-details-id",
    path: "",
    meta: index82KtHcZvrwMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "audience-tag-new",
    path: "/audience/tag/new",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-new-import-id",
    path: "import/:id()",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id].vue").then(m => m.default || m),
    children: [
  {
    name: match_45fieldsFOevKjxc8EMeta?.name,
    path: "match-fields",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-new-import-id-match-fields",
    path: "",
    meta: indexdckTNWfY0lMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: select_45file26YZWEojC4Meta?.name,
    path: "select-file",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-new-import-id-select-file",
    path: "",
    meta: indexsodgHFJgIgMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: tableITD1FnXegMMeta?.name,
    path: "/audience/tag/table",
    meta: tableITD1FnXegMMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-table",
    path: "",
    meta: indexm4IKTjzoY2Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-automations-edit-id",
    path: "/automate/automations/edit/:id()",
    meta: _91id_938pOaAhOyHKMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "automate-automations-edit-id-configure",
    path: "configure",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/configure/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-edit-id-edit",
    path: "edit",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-edit-id-name",
    path: "name",
    meta: indexHIvlQVN286Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/name/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-automations-info-id",
    path: "/automate/automations/info/:id()",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/info/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-new",
    path: "/automate/automations/new",
    meta: newEG3IAFiASHMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-automations-new-configure",
    path: "configure",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/configure/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-new-create",
    path: "create",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/create/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-new-name",
    path: "name",
    meta: indexKLq3hkSsCOMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/name/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-automations-table",
    path: "/automate/automations/table",
    meta: tableJNPw0RdjeHMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/table.vue").then(m => m.default || m)
  },
  {
    name: "automate-bulk-action-table",
    path: "/automate/bulk-action/table",
    meta: indexLEsu5MkORyMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/bulk-action/table/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id",
    path: "/automate/workflow/:id()",
    meta: _91id_93cpCvmt2BA4Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-id-edit",
    path: "edit",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: overvieweyacFGhZaWMeta?.name,
    path: "email/:eid()/overview",
    meta: overvieweyacFGhZaWMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-id-email-eid-overview",
    path: "",
    meta: indexSS8CdvV65QMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id-email-eid-overview-performance",
    path: "performance",
    meta: index3MeVYi4Y78Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-workflow-id-goal",
    path: "goal",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/goal.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id-report",
    path: "report",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/report.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id-timeline",
    path: "timeline",
    meta: timelinezX36nSqcQGMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/timeline.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-workflow-new",
    path: "/automate/workflow/new",
    meta: newZeLrZoZUWNMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-new-edit",
    path: "edit",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-new-goal",
    path: "goal",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/goal/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-new-template",
    path: "template",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/template/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-new-timeline",
    path: "timeline",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/timeline/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tableaGX6sfKCZ3Meta?.name,
    path: "/automate/workflow/table",
    meta: tableaGX6sfKCZ3Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-table-active",
    path: "active",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-table-draft",
    path: "draft",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-table",
    path: "",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-table-paused",
    path: "paused",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/paused/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewI5INdtBHJKMeta?.name,
    path: "/campaign/:id()/overview",
    meta: overviewI5INdtBHJKMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-id-overview",
    path: "",
    meta: indexCSyHjCXaDKMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-id-overview-performance",
    path: "performance",
    meta: indexf52Z5FdHMiMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-abtest-id",
    path: "/campaign/abtest/:id()",
    meta: index9rtJNU1f4nMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewYdifhnKenaMeta?.name,
    path: "/campaign/abtest/:id()/overview",
    meta: overviewYdifhnKenaMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-id-overview",
    path: "",
    meta: indexj0ftJTqyFRMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-id-overview-performance",
    path: "performance",
    meta: indexloJuNdGfOeMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-abtest-edit-id",
    path: "/campaign/abtest/edit/:id()",
    meta: _91id_93e0Gfn4sONtMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-edit-id-content",
    path: "content",
    meta: indexNM73zW5sz1Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-design",
    path: "design",
    meta: index9cVO6Y2UjrMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-recipient",
    path: "recipient",
    meta: index0cNVlz4BVAMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-review",
    path: "review",
    meta: index8G1fuFaFNcMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-schedule",
    path: "schedule",
    meta: indexjk4X6awR8GMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-abtest-new",
    path: "/campaign/abtest/new",
    meta: newWVyS7fdwPnMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-new-content",
    path: "content",
    meta: index1tbYfv0VM6Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-design",
    path: "design",
    meta: indexmFaPIEfGXgMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-recipient",
    path: "recipient",
    meta: indexSlpoKpzrpPMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-review",
    path: "review",
    meta: indexcj1WaqCHfsMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-schedule",
    path: "schedule",
    meta: indexNn45EF0YGZMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tableqPEWVyjwamMeta?.name,
    path: "/campaign/abtest/table",
    meta: tableqPEWVyjwamMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-table-draft",
    path: "draft",
    meta: indexudlXcMMBHUMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-finished",
    path: "finished",
    meta: indexdSmZEGCF8MMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/finished/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table",
    path: "",
    meta: indexSZQP7awdC1Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-scheduled",
    path: "scheduled",
    meta: index7xbjWESBkEMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/scheduled/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-tested",
    path: "tested",
    meta: indexw3EA7iYW2bMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/tested/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-testing",
    path: "testing",
    meta: index73rs6oFWxgMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/testing/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-winnerScheduled",
    path: "winnerScheduled",
    meta: indextZpeTh71VZMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerScheduled/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-winnerSending",
    path: "winnerSending",
    meta: indexCsGJuIillhMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerSending/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewn77H8rWae5Meta?.name,
    path: "/campaign/autotrigger/:id()/overview",
    meta: overviewn77H8rWae5Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-id-overview",
    path: "",
    meta: indexKKUhJcXtmtMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-id-overview-performance",
    path: "performance",
    meta: indexLidWF1W9WuMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-autotrigger-edit-id",
    path: "/campaign/autotrigger/edit/:id()",
    meta: _91id_93qZfD1sJoqTMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-edit-id-content",
    path: "content",
    meta: indexIMvmUhVBhmMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-design",
    path: "design",
    meta: index0435nQqBR6Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-review",
    path: "review",
    meta: indext14IMmUnVpMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-sender",
    path: "sender",
    meta: indexxTlS5Zu6aeMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/sender/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-trigger",
    path: "trigger",
    meta: indexk25xnVasWbMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/trigger/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-autotrigger-new",
    path: "/campaign/autotrigger/new",
    meta: newun9pqjVxZGMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-new-content",
    path: "content",
    meta: index7lYeqznczqMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-design",
    path: "design",
    meta: indexjzn2FI5jJTMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-review",
    path: "review",
    meta: indexgbsXwS616XMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-sender",
    path: "sender",
    meta: indexst0Oo9nu5yMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/sender/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-trigger",
    path: "trigger",
    meta: indexP9LMcjk9ZBMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/trigger/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tablewVvZYN97SlMeta?.name,
    path: "/campaign/autotrigger/table",
    meta: tablewVvZYN97SlMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-table-active",
    path: "active",
    meta: indexO0suNSZQDwMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-table-draft",
    path: "draft",
    meta: indexl1HGbweZv3Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-table",
    path: "",
    meta: indexyNvdo2BYvbMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-drip-id",
    path: "/campaign/drip/:id()",
    meta: indexDAce4A2O1cMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: overview5TGDx9ykJkMeta?.name,
    path: "/campaign/drip/:id()/overview",
    meta: overview5TGDx9ykJkMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-id-overview",
    path: "",
    meta: indexOEodS0MP3bMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-id-overview-performance",
    path: "performance",
    meta: indexUBNnxrSnsYMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-drip-edit-id",
    path: "/campaign/drip/edit/:id()",
    meta: _91id_93S2dTszNYiYMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-edit-id-content",
    path: "content",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-edit-id-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-drip-new",
    path: "/campaign/drip/new",
    meta: new3tF3J80pdKMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-new-content",
    path: "content",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-new-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tableqf2afO8gybMeta?.name,
    path: "/campaign/drip/table",
    meta: tableqf2afO8gybMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-table-active",
    path: "active",
    meta: index6j42cymmxjMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-table",
    path: "",
    meta: indexqqkzIIZOp6Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-table-paused",
    path: "paused",
    meta: indexe9LqpwQHQZMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/paused/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-newsletter-edit-id",
    path: "/campaign/newsletter/edit/:id()",
    meta: _91id_93m7PDzCvp8NMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-newsletter-edit-id-content",
    path: "content",
    meta: indexMQZ56phUhFMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-design",
    path: "design",
    meta: indexZJA4TE78DXMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-recipient",
    path: "recipient",
    meta: indexQ9uVkwvqDIMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-review",
    path: "review",
    meta: indexln5m0hWno3Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-schedule",
    path: "schedule",
    meta: indexzaqkBN77TrMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-sender",
    path: "sender",
    meta: indexGtkiUz1nCqMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-newsletter-new",
    path: "/campaign/newsletter/new",
    meta: newgnl7i0uI8xMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-newsletter-new-content",
    path: "content",
    meta: indexonecWUZIsdMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-design",
    path: "design",
    meta: index3QBJIku6dIMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-recipient",
    path: "recipient",
    meta: indexPB5pD5oIgHMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-review",
    path: "review",
    meta: indexcOpIPmDT96Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-schedule",
    path: "schedule",
    meta: indextYbetsRGBwMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-sender",
    path: "sender",
    meta: indextpmhK14bAjMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: table2axTko2KjmMeta?.name,
    path: "/campaign/newsletter/table",
    meta: table2axTko2KjmMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-newsletter-table-draft",
    path: "draft",
    meta: indexoyrSKg9eMpMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table",
    path: "",
    meta: indexDTg2nms23zMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-quarantined",
    path: "quarantined",
    meta: indexJ6ZJUhMJ4xMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/quarantined/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-scheduled",
    path: "scheduled",
    meta: indexoG2CdPmsLMMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/scheduled/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-sending",
    path: "sending",
    meta: indexgyRUukb0CTMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sending/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-sent",
    path: "sent",
    meta: indexVlLASWpJDDMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sent/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-report",
    path: "/campaign/report",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/report/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-id",
    path: "/campaign/rss/:id()",
    meta: indexijus1cht3cMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewHbFwZ6MhaeMeta?.name,
    path: "/campaign/rss/:id()/overview",
    meta: overviewHbFwZ6MhaeMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-id-overview",
    path: "",
    meta: indexC5AaarrHnOMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-id-overview-performance",
    path: "performance",
    meta: index08cce0sc66Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-rss-edit-id",
    path: "/campaign/rss/edit/:id()",
    meta: _91id_93HaDbowSxPjMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-edit-id-basic",
    path: "basic",
    meta: indexzHrWFUjECUMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-edit-id-content",
    path: "content",
    meta: index4nQywI1thAMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-edit-id-recipient",
    path: "recipient",
    meta: indexxhcIvlZSj3Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-edit-id-schedule",
    path: "schedule",
    meta: indexfNOv5SR8XTMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/schedule/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-rss-new",
    path: "/campaign/rss/new",
    meta: neweXezCSCpQDMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-new-basic",
    path: "basic",
    meta: indexmnPFqNxFSQMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-new-content",
    path: "content",
    meta: index50imaiZOvCMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-new-recipient",
    path: "recipient",
    meta: index7DbfXYvEKcMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-new-schedule",
    path: "schedule",
    meta: indexBESmua1AhfMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/schedule/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tablebSKj59uXmdMeta?.name,
    path: "/campaign/rss/table",
    meta: tablebSKj59uXmdMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-table-active",
    path: "active",
    meta: indexHIT6XbzalsMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-table-draft",
    path: "draft",
    meta: indexd65ljMERqbMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-table",
    path: "",
    meta: indexwMSRYKYc31Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-table-paused",
    path: "paused",
    meta: indexWq4MZyP3jMMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/paused/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexDYVd29JlZjMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "download",
    path: "/download",
    meta: indexV9HAoH8nHdMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/error/index.vue").then(m => m.default || m)
  },
  {
    name: overviewvh8vur7XBeMeta?.name,
    path: "/form/embedded/:id()/overview",
    meta: overviewvh8vur7XBeMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "form-embedded-id-overview",
    path: "",
    meta: indexv4SM4YLLdmMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-id-overview-instructions",
    path: "instructions",
    meta: indexmzE74WItPPMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/instructions/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-id-overview-performance",
    path: "performance",
    meta: indexEgRvk1s6ogMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/performance/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-id-overview-submission",
    path: "submission",
    meta: indexmD5b46geHXMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/submission/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-embedded-edit-id",
    path: "/form/embedded/edit/:id()",
    meta: _91id_93RkMRHJuOMPMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "form-embedded-edit-id-basic",
    path: "basic",
    meta: indexGj5O9yQXOnMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-content",
    path: "content",
    meta: indexVnruggH9dlMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-design",
    path: "design",
    meta: indexjpnqKXaqK7Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-success",
    path: "success",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-targeting",
    path: "targeting",
    meta: indexdHStBcAfbDMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/targeting/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewg7EECaEa3OMeta?.name,
    path: "/form/landing-page/:id()/overview",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "form-landing-page-id-overview",
    path: "",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-id-overview-instructions",
    path: "instructions",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/instructions/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-id-overview-performance",
    path: "performance",
    meta: indexyJpg1zJG3SMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/performance/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-id-overview-submission",
    path: "submission",
    meta: indexQAcjgm0zQWMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/submission/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-landing-page-edit-id",
    path: "/form/landing-page/edit/:id()",
    meta: _91id_93BOM0SrZhpuMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "form-landing-page-edit-id-basic",
    path: "basic",
    meta: indexwCCmjuUpoMMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-content",
    path: "content",
    meta: indexp1yaC6wHuaMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-design",
    path: "design",
    meta: indexb3jrs2P6a3Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-success-page",
    path: "success-page",
    meta: index8gUbzRzm0PMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success-page/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-success",
    path: "success",
    meta: indexkkGwPzHUKBMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-new",
    path: "/form/new",
    meta: newBrKHWsWdxPMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new.vue").then(m => m.default || m),
    children: [
  {
    name: "form-new-basic-EmbeddedForm",
    path: "basic/EmbeddedForm",
    meta: EmbeddedFormfb8L5GpNmQMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-basic",
    path: "basic",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-basic-LandingPage",
    path: "basic/LandingPage",
    meta: LandingPageVpBlBubsUrMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-basic-Popup",
    path: "basic/Popup",
    meta: PopupDMIDjHOU95Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-behaviour",
    path: "behaviour",
    meta: index4F3CjD79ggMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/behaviour/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-EmbeddedForm",
    path: "content/EmbeddedForm",
    meta: EmbeddedFormfNwiTXJeTyMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-EmbeddedForm2",
    path: "content/EmbeddedForm2",
    meta: EmbeddedForm2IuIWGgnM33Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm2.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content",
    path: "content",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-LandingPage",
    path: "content/LandingPage",
    meta: LandingPage0nXDFjibGSMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-Popup",
    path: "content/Popup",
    meta: PopupYvfm5Tn9IVMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-Popup2",
    path: "content/Popup2",
    meta: Popup2J7bCPkIZX0Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup2.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design-EmbeddedForm",
    path: "design/EmbeddedForm",
    meta: EmbeddedFormqZ5dOXdjoGMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design",
    path: "design",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design-LandingPage",
    path: "design/LandingPage",
    meta: LandingPage55vY9bb1qfMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design-Popup",
    path: "design/Popup",
    meta: PopupqU74GYsXJsMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-page",
    path: "success-page",
    meta: indexEuZ8E3ODfNMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success-page/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-EmbeddedForm",
    path: "success/EmbeddedForm",
    meta: EmbeddedFormCwq5NJieTWMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success",
    path: "success",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-LandingPage",
    path: "success/LandingPage",
    meta: LandingPageAEsMYNXsxZMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-Popup",
    path: "success/Popup",
    meta: PopupPdRJLNWpyEMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-targeting-EmbeddedForm",
    path: "targeting/EmbeddedForm",
    meta: EmbeddedFormQXl3npE8V0Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-targeting",
    path: "targeting",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-targeting-Popup",
    path: "targeting/Popup",
    meta: PopupFgWT5uiWZcMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-type",
    path: "type",
    meta: index5oT6W0eb8yMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/type/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewCogqlyXmKyMeta?.name,
    path: "/form/popup/:id()/overview",
    meta: overviewCogqlyXmKyMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "form-popup-id-overview",
    path: "",
    meta: indexTqsJfwNjUlMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-id-overview-instructions",
    path: "instructions",
    meta: indexJMKNb2UAfiMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/instructions/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-id-overview-performance",
    path: "performance",
    meta: indexbcZStL8vNZMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/performance/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-id-overview-submission",
    path: "submission",
    meta: indexOKgdQNQWkoMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/submission/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-popup-edit-id",
    path: "/form/popup/edit/:id()",
    meta: _91id_93Q8cBTrv8cqMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "form-popup-edit-id-basic",
    path: "basic",
    meta: indexxHPtrYr131Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-behaviour",
    path: "behaviour",
    meta: indexd50mbgM4jSMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/behaviour/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-content",
    path: "content",
    meta: indexsFZ6NM9JxeMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-design",
    path: "design",
    meta: index62HHRks9GPMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-success",
    path: "success",
    meta: indexdrWmgUUTtGMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-targeting",
    path: "targeting",
    meta: indexQJpfXNDbGLMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/targeting/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-table",
    path: "/form/table",
    meta: tablellxK9Ofsn4Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table.vue").then(m => m.default || m),
    children: [
  {
    name: "form-table-embed",
    path: "embed",
    meta: indexkhqZv8KQ1UMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/embed/index.vue").then(m => m.default || m)
  },
  {
    name: "form-table-landing-page",
    path: "landing-page",
    meta: indexR4ylKG7DaFMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/landing-page/index.vue").then(m => m.default || m)
  },
  {
    name: "form-table-popup",
    path: "popup",
    meta: indexPNlPchZng8Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/popup/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index6MhSCqgr6HMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-click-funnels",
    path: "/integration/click-funnels",
    meta: indexpynyYqhSASMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/click-funnels/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-convert-box",
    path: "/integration/convert-box",
    meta: indexTdHWilxcodMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/convert-box/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-demio",
    path: "/integration/demio",
    meta: indexlJei7uuEHpMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/demio/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-hubspot",
    path: "/integration/hubspot",
    meta: indexEO3kLvXuO0Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/hubspot/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-instapage",
    path: "/integration/instapage",
    meta: indexMz0emqpBxoMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/instapage/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-intercom",
    path: "/integration/intercom",
    meta: indexjoudENH79AMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/intercom/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-paypal",
    path: "/integration/paypal",
    meta: indexFT6MHgFmPCMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/paypal/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-pipedrive",
    path: "/integration/pipedrive",
    meta: indexmNEatce6T3Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/pipedrive/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-samcart",
    path: "/integration/samcart",
    meta: indexWghSBctyA9Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/samcart/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-stripe",
    path: "/integration/stripe",
    meta: index2p5chpplQRMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/stripe/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-teachable",
    path: "/integration/teachable",
    meta: index1oVuqco6j8Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/teachable/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-twilio",
    path: "/integration/twilio",
    meta: indexAonEJWFX2kMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/twilio/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-woo-commerce",
    path: "/integration/woo-commerce",
    meta: indexLKLqRXEjj5Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woo-commerce/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-woorise",
    path: "/integration/woorise",
    meta: indexdRuIdALZ2HMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woorise/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-zapier",
    path: "/integration/zapier",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/zapier/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexG9hivKzlCrMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexzo1Niq7afyMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-member",
    path: "/onboarding/member",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/member/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexiYVhr1NJ6qMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: indexjzIXl8Ij93Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword",
    path: "/resetpassword",
    meta: indexfs1GLJA5CyMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword-newpassword",
    path: "/resetpassword/newpassword",
    meta: indexXFfsC9MWPYMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/newpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-billing",
    path: "billing",
    meta: indexlYxm3iqxB8Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-connectors",
    path: "connectors",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-connectors-api",
    path: "api",
    meta: indextvIwGhWvxDMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/api/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-connectors-webhook",
    path: "webhook",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/webhook/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-custom-properties",
    path: "custom-properties",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-custom-properties-custom-events-id",
    path: "custom-events/:id()",
    meta: _91id_931RI4AnIfzlMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/[id].vue").then(m => m.default || m)
  },
  {
    name: "setting-custom-properties-custom-events",
    path: "custom-events",
    meta: indexjFUdA0rL0WMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-custom-properties-custom-fields",
    path: "custom-fields",
    meta: indexgOvXDX6JtAMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-fields/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-home-page",
    path: "home-page",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/home-page/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-marketplace",
    path: "marketplace",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-marketplace-add-ons",
    path: "add-ons",
    meta: indexjd4ig0L0aeMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/add-ons/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-marketplace-integrations",
    path: "integrations",
    meta: indexrCZxm19hGOMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-marketplace-partners",
    path: "partners",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/partners/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-setup",
    path: "setup",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-setup-company-information",
    path: "company-information",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/company-information/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-domain-whitelisting",
    path: "domain-whitelisting",
    meta: index1twFt3p1WcMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/domain-whitelisting/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-editor",
    path: "editor",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/editor/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/sender/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-template",
    path: "template",
    meta: indexLpDBPkmRcZMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/template/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-unsubscribe",
    path: "unsubscribe",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/unsubscribe/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-team-members",
    path: "team/members",
    meta: indexbz711KhV01Meta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/team/members/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: "template-id-edit",
    path: "/template/:id()/edit",
    meta: indexcNjujhfVwnMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "template-id",
    path: "/template/:id()",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "template-new",
    path: "/template/new",
    meta: indexwQl3Dy6ATNMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/new/index.vue").then(m => m.default || m)
  },
  {
    name: detailsQd0ZRXYL1JMeta?.name,
    path: "/tickets/:id()/details",
    meta: detailsQd0ZRXYL1JMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details.vue").then(m => m.default || m),
    children: [
  {
    name: "tickets-id-details",
    path: "",
    meta: indexXr5F6lV8FHMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tickets-new",
    path: "/tickets/new",
    meta: indexRMien9haGfMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/new/index.vue").then(m => m.default || m)
  },
  {
    name: tableCvBg3SvZiWMeta?.name,
    path: "/tickets/table",
    meta: tableCvBg3SvZiWMeta || {},
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table.vue").then(m => m.default || m),
    children: [
  {
    name: "tickets-table",
    path: "",
    component: () => import("/var/lib/go-agent1/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table/index.vue").then(m => m.default || m)
  }
]
  }
]